import React, { useState, useEffect, useContext } from "react";
import Downshift from "downshift";
import { LoginContext } from "../context/login-context/login-context";
import { useTranslation } from "../hooks/use-translation";

interface ISelectClub {
  handleSelectClubVisibility: () => void;
}

export const SelectClub = ({ handleSelectClubVisibility }: ISelectClub) => {
  const { setActiveClub, clientCode, availableClubs } = useContext(LoginContext);
  const { translate } = useTranslation();
  const [newSelectedClub, setNewSelectedClub] = useState("");

  useEffect(() => {
    if (newSelectedClub !== "") {
      setActiveClub(newSelectedClub);
      handleSelectClubVisibility();
    }
  }, [newSelectedClub, handleSelectClubVisibility, setActiveClub]);

  const getSelectScope = (clientCode: string|undefined): string => {
    //ugly trick to display a specific text for ffh01.
    //there is only 1 i18n file (1 en, 1 fr) for all the dma clients
    if (clientCode) {
      if (clientCode === 'ffh01') {
        return 'Chercher une ligue';
      } else if (clientCode === 'ffa01') {
        return 'Chercher une structure';
      }
    }
    return translate("general.selectClub");
  }

  return (
    <>
      <Downshift
        onChange={(selection) => setNewSelectedClub(selection)}
        itemToString={(item) => (item ? item : "")}
        defaultIsOpen={true}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getToggleButtonProps,
          inputValue,
          highlightedIndex,
          selectedItem,
          isOpen,
          openMenu,
        }) => (
          <div className="combo-box field has-addons">
            <div className="control">
              <input
                {...getInputProps({
                  onFocus: () => {
                    openMenu();
                  },
                })}
                className="input"
                placeholder={getSelectScope(clientCode)}
              />
            </div>
            <div className="control">
              {isOpen ? (
                <button onClick={handleSelectClubVisibility} aria-label={"toggle menu"} className="button">
                  X
                </button>
              ) : (
                <button {...getToggleButtonProps()} aria-label={"toggle menu"} className="button">
                  &#8595;
                </button>
              )}
            </div>
            <ul {...getMenuProps()} className="combo-box--menu">
              {isOpen &&
                availableClubs &&
                Object.keys(availableClubs)
                  .filter((item) => !inputValue || item.includes(inputValue))
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: `${item}${index}`,
                        item,
                        index,
                        style: {
                          backgroundColor: highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        },
                      })}
                    >
                      {item}
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </Downshift>
    </>
  );
};

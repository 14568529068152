import React, {useContext, useState} from "react";
import styles from "./navbar.module.scss";
import {LoginContext} from "../context/login-context/login-context";
import {Link, useNavigate, useMatch} from "@reach/router";
import {SelectClub} from "./select-club";
import {useTranslation} from "../hooks/use-translation";
import cx from "classnames";
import DocumentationIcon from "./icons/documentation";
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";

type NavbarType = {
    urlLink?:string;
};

export const Navbar: React.FC<NavbarType> = ({
    urlLink
  }) => {
    const {activeClub, user: login, availableClubs, clientCode} = useContext(LoginContext);
    const navigate = useNavigate();
    const {translate} = useTranslation();
    const [selectClubVisibility, setSelectClubVisibility] = useState(false);
    const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState<boolean>(false);

    const isIndexRoute = useMatch("/");
    const isNewCampaignRoute = useMatch("/campaigns/new-campaign/*");
    const isEditSmsCampaignRoute = useMatch("/campaigns/edit-SMS-campaign/*");
    const isEditEmailCampaignRoute = useMatch("/campaigns/edit-EMAIL-campaign/*");
    const isDashboardRoute = useMatch("/campaigns");
    const title = translate("navbar.tooltip.title");
    const handleLogout = () => {
        navigate("/logout");
    };

    const handleSelectClubVisibility = () => {
        setSelectClubVisibility((isVisible) => !isVisible);
    };
    const handleRedirectUrlButton = () => {
        window.open(String(urlLink), '_blank');
    };
    const handleHamburgerMenuVisibility = () => {
        setIsHamburgerMenuVisible((isHamburgerMenuVisible) => !isHamburgerMenuVisible);
    };

    const getNavBarScope = (clientCode: string | undefined): string | undefined => {
        //ugly trick to display a specific text for ffh01.
        //there is only 1 i18n file (1 en, 1 fr) for all the dma clients
        if (clientCode) {
            if (clientCode === 'ffh01') {
                return 'de ligue';
            } else if (clientCode === 'ffa01') {
                return 'de structure';
            }
        }
        return translate("navbar.scope");
    }

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <a href="/" title="Home Page">
                    <img src={`/skin/${clientCode}/images/logo.png`} alt="logo" className="navbar-item navbar-logo"/>
                </a>
                <h1 className="navbar-item has-text-weight-bold is-size-5">
                    {activeClub ? activeClub : "Distributed Marketing Application"}
                </h1>
                {!isIndexRoute && (
                    <div className={`navbar-item ${styles.burgerMenu}`}>
                        <button
                            className="button"
                            aria-label="menu"
                            aria-expanded={isHamburgerMenuVisible ? "true" : "false"}
                            onClick={handleHamburgerMenuVisibility}
                        >
              <span className="icon is-small">
                <i
                    className={cx("fa", "fa-lg", {
                        "fa-times": isHamburgerMenuVisible,
                        "fa-bars": !isHamburgerMenuVisible,
                    })}
                ></i>
              </span>
                        </button>
                    </div>
                )}
            </div>
            <div
                className={cx("navbar-menu", {
                    "is-active": isHamburgerMenuVisible,
                })}
            >
                <div className="navbar-start">
                    <div className="navbar-item">
                        {isNewCampaignRoute && <Link to="/campaigns">{translate("general.backToCampaigns")}</Link>}
                        {isEditSmsCampaignRoute && <Link to="/campaigns">{translate("general.backToCampaigns")}</Link>}
                        {isEditEmailCampaignRoute &&
                            <Link to="/campaigns">{translate("general.backToCampaigns")}</Link>}
                    </div>
                </div>
                <div className="navbar-end">
                    {isDashboardRoute && (
                        <>
                            <div className="navbar-item">
                                {availableClubs &&
                                    Object.keys(availableClubs).length >= 2 &&
                                    (!selectClubVisibility ? (
                                        <p className={`is-primary ${styles.cursorPointer}`}
                                           onClick={handleSelectClubVisibility}>
                                            {translate("navbar.change")} {getNavBarScope(clientCode)}
                                        </p>
                                    ) : (
                                        <SelectClub handleSelectClubVisibility={handleSelectClubVisibility}/>
                                    ))}
                            </div>
                        </>
                    )}
                    {login &&
                        (
                            <div className="navbar-item">
                                <div className={"px-3"}>
                                    <Tooltip
                                        title={String(title)}>
                                        <IconButton
                                            style={{borderRadius: "4px", height: "42px", width: "40px"}}
                                            size="small"
                                            onClick={handleRedirectUrlButton}
                                        ><DocumentationIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <button className="button is-primary" onClick={handleLogout}>
                                    {translate("navbar.logout")}
                                </button>
                            </div>
                        )}
                </div>
            </div>
        </nav>
    );
};
